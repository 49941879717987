import React from 'react';

export const NextWrapper = ({ children, css }) => {
  return (
    <>
      <style jsx global>
        {`
          .scrolling-child {
            overflow: auto;
          }

          .scrolling-child-x {
            overflow-y: hidden;
            overflow-x: auto;
          }

          .scrolling-child-y {
            overflow-x: hidden;
            overflow-y: auto;
          }

          .scrolling-child-parent {
            align-content: flex-start;
            overflow: hidden;
          }

          #__next {
            display: grid;
            min-height: 100vh;
            ${css}
          }
        `}
      </style>
      {children}
    </>
  );
};
