import AWS from 'aws-sdk';
import { isDev, isProduction } from 'app/config/is-dev';

let env = isDev ? 'dev' : isProduction ? 'prod' : 'stage';

// TODO: migrate this to env safe
if (!process.env.NEXT_PUBLIC_S3_ENDPOINT || !process.env.NEXT_PUBLIC_S3_BUCKET_NAME) {
  throw new Error('Missing S3 config');
}

export const S3Bucket = process.env.NEXT_PUBLIC_S3_BUCKET_NAME;
export const S3BaseUrl = `https://${S3Bucket}.${process.env.NEXT_PUBLIC_S3_ENDPOINT}`;

const FOLDER_NAME = `glink-uploads-${env}`;

export const getResourceKey = (id: string) => `${FOLDER_NAME}/${id}`;
export const getResourceUrl = <T extends string | { id: string } | null>(
  id: T
): T extends null ? undefined : string => {
  if (!Boolean(id) || id === null) {
    return undefined as any;
  }

  return `${S3BaseUrl}/${getResourceKey(typeof id === 'string' ? id : id.id)}` as any;
};

export const s3 = new AWS.S3({
  apiVersion: '2006-03-01',
  accessKeyId: process.env.S3_ACCESS,
  secretAccessKey: process.env.S3_SECRET,
  endpoint: new AWS.Endpoint(process.env.NEXT_PUBLIC_S3_ENDPOINT),
});
