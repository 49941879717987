import { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import { appName } from 'app/config/appName';

export const useClickOnTitle = () => {
  const [clicks, setClicks] = useState(0);
  const router = useRouter();

  const toast = useToast();
  const t = (text: string) => toast({ title: text });
  const onTitleClick = () => {
    setClicks((c) => c + 1);
    switch (clicks) {
      case 5:
        return t('Why are you clicking here?');
      case 9:
        return t('What are you trying to do?');
      case 10:
        return t('Ok here you go');
      case 11:
        return t('STOP');
      case 12:
        return t('Ok keep on going');
      case 20:
        return t('Done now?');
      case 25:
        return t("Don't you have better things to do");
      case 28:
        return t('STOP !!!!');
      case 29:
        return t("Why are you clicking, it already changed it's name");
      case 30:
        return t('OH FFS THIS IS THE NAME OK, HAPPY NOW???');
    }
  };

  useEffect(() => {
    setClicks(0);
  }, [router.asPath]);

  const title = clicks > 30 ? 'Fungangarzione' : clicks > 10 ? 'Fungarzione' : appName;

  return [title, onTitleClick] as const;
};
