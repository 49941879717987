import { useRouter } from 'next/router';
import { useParam } from '@blitzjs/next';
import React from 'react';
import superjson from 'superjson';

import LoadingScreen from 'app/components/LoadingScreen';
import { BlitzPage } from 'app/types/page-types';

export const useSingleParam = (a: string): string => useParam(a) as string;
export const useNumberParam = (a: string): number => useParam(a, 'number') as number;

// TODO: Use this on all pages that have any static content
export type StaticPageType<P> = BlitzPage & React.FC<P extends string ? Record<P, string> : P>;
type StaticReturn = ReturnType<BlitzPage & React.FC>;
type Comp<P> = (props: P) => StaticReturn;

export const useHandleString = <P,>(dataString: string, comp: Comp<P>): StaticReturn => {
  const { isFallback } = useRouter();
  if (isFallback || !dataString) return <LoadingScreen />;

  let parsed;

  try {
    parsed = superjson.parse<P>(dataString);
  } catch (err) {
    return <div>{err.toString()}</div>;
  }

  return comp(parsed);
};

export const useQueryParam = (key?: string): string | undefined => {
  const router = useRouter();
  const val = router.query[key || 'id'];

  return Array.isArray(val) ? val[0] : val;
};

export * from './magic';
