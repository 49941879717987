import React from 'react';
import { Avatar, AvatarProps } from '@chakra-ui/avatar';
import { User, Image } from '@prisma/client';

import { getResourceUrl } from 'app/data/images/s3/config';

export type UserWithAvatar = Pick<User, 'id' | 'name'> & { avatarImage: Pick<Image, 'id'> | null };
interface UserAvatarProps {
  user: UserWithAvatar;
  avatarProps?: AvatarProps;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({ user, avatarProps }) => {
  return <Avatar {...avatarProps} src={getResourceUrl(user.avatarImage)} name={user.name} />;
};
