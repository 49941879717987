export enum margin {
  horizontal = 'marginRight',
  vertical = 'marginBottom',
  left = 'marginLeft',
  top = 'marginTop',
}

const px = (a) => (a?.includes?.('px') ? a : `${a}px`);

export const flex: any = {
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
  },
  horizontalReverse: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  vertical: {
    display: 'flex',
    flexDirection: 'column',
  },
  verticalReverse: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  flexWrap: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  centerHorizontal: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrap: {
    flexWrap: 'wrap',
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
  centerHorizontalH: {
    justifyContent: 'center',
  },
  centerHorizontalV: {
    alignItems: 'center',
  },
  centerVertical: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  centerVerticalH: {
    alignItems: 'center',
  },
  centerVerticalV: {
    justifyContent: 'center',
  },
  spaceAround: {
    justifyContent: 'space-around',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  justifyEnd: {
    justifyContent: 'flex-end',
  },
  alignStart: {
    alignContent: 'flex-start',
  },
  stretchSelf: {
    alignSelf: 'stretch',
  },
  selfEnd: {
    alignSelf: 'flex-end',
  },
  spacing: (direction, spacing) => ({
    '& > *': {
      [margin[direction]]: `${px(spacing)} !important`,
    },
    '& > *:last-child': {
      [margin[direction]]: `0 !important`,
    },
  }),
};

export default flex;
