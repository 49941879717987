import React, { forwardRef } from 'react';
import { Box as OriginalBox, BoxProps } from '@chakra-ui/react';
import { fixedHeight, fixedWidth, grid, gridProps } from 'styled-mixins';
import styled from '@emotion/styled';
import { Property } from 'csstype';

import { debugStyle } from 'app/styles/debugger';
import flex from './flex';

export interface CommonProps {
  isWrap?: boolean;
  spaceBetween?: boolean;
  spaceAround?: boolean;
  justifyEnd?: boolean;
  debug?: any;
  justifyStart?: boolean;
  flex?: number;
  spaceFirst?: boolean;
  spacing?: number | string;
  className?: string;
  spaceBottom?: boolean;
  alignStart?: boolean;
  alignItemsStart?: boolean;
  flexDirection?: Property.FlexDirection;
  center?: boolean;
  centerV?: boolean;
  centerH?: boolean;
  noShrink?: boolean;
  styles?: any;
  invert?: boolean;
  reverse?: boolean;
  fullW?: boolean;
  fullH?: boolean;
  full?: boolean;
}

export enum margin {
  horizontal = 'marginRight',
  vertical = 'marginBottom',
  left = 'marginLeft',
  top = 'marginTop',
}

export const Box: React.FC<
  {
    tr?: boolean | number;
    br?: number;
  } & BoxProps
> = ({ children, tr, br, ...props }) => (
  <OriginalBox
    {...{
      ...(br && { borderRadius: br }),
      ...(tr && { transition: 'all 100ms linear' }),
      ...props,
    }}
  >
    {children}
  </OriginalBox>
);

const px = (a) => (a?.includes?.('px') ? a : `${a}px`);

const getOppositeMarginSide = (dir) => (dir === 'horizontal' ? 'left' : 'top');
const getInverseDirection = (dir) => (dir === 'horizontal' ? 'vertical' : 'horizontal');

export const common = (direction: string, reverse) => (p: CommonProps) => {
  let oppositeDirection = getInverseDirection(direction);
  direction = reverse === true ? getOppositeMarginSide(direction) : direction;
  return {
    alignItems: 'flex-start',
    className: p.className,
    ...(p.spaceBetween && flex.spaceBetween),
    ...(p.isWrap && flex.wrap),
    ...(p.spaceAround && flex.spaceAround),
    ...(p.justifyEnd && flex.justifyEnd),
    ...(p.justifyStart && { justifyContent: 'flex-start' }),
    ...(p.alignStart && flex.alignStart),
    ...(p.alignItemsStart && flex.alignItemsStart),
    ...(p.flex && { flex: p.flex }),
    ...(p.debug && {
      ...(typeof p.debug === 'boolean' && { border: '1px solid red' }),
      ...(typeof p.debug !== 'boolean' && debugStyle(p.debug)),
    }),
    ...(p.spaceFirst && {
      '& :first-child': {
        [margin[direction]]: `${px(p.spaceFirst)} !important`,
      },
    }),
    ...(p.spacing && {
      '& > *': {
        [margin[direction]]: `${px(p.spacing)} !important`,
        '@media screen and (max-width: 480px)': {
          ...(p.isWrap && {
            [margin[oppositeDirection]]: `${px(p.spacing)} !important`,
          }),
        },
        ...(p.spaceBottom && { marginBottom: `${px(p.spacing)} !important` }),
      },
      '& > *:last-child': {
        [margin[direction]]: `0 !important`,
        ...(p.spaceBottom && { marginBottom: `0 !important` }),
      },
    }),
    ...(p.noShrink && {
      flexShrink: 0,
    }),
    ...(p.fullW && { width: '100%' }),
    ...(p.fullH && { height: '100%' }),
    ...(p.full && { height: '100%', width: '100%' }),
    ...(p.styles && p.styles),
  };
};

const horizontalProps = (props: any) => {
  const { center, centerV, reverse, centerH } = props;
  return {
    ...common('horizontal', reverse)(props),
    ...flex.horizontal,
    ...(reverse && flex.horizontalReverse),
    ...(center && flex.centerHorizontal),
    ...(centerV && flex.centerHorizontalV),
    ...(centerH && flex.centerHorizontalH),
  };
};

const verticalProps = (props: any) => {
  const { center, reverse, centerV, centerH } = props;
  return {
    ...common('vertical', reverse)(props),
    ...flex.vertical,
    ...(reverse && flex.verticalReverse),
    ...(center && flex.centerVertical),
    ...(centerV && flex.centerVerticalV),
    ...(centerH && flex.centerVerticalH),
  };
};

const getHorizontalProps = ({ invert = false, ...rest }) => {
  return !invert ? horizontalProps(rest) : verticalProps(rest);
};

const getVerticalProps = ({ invert = false, ...rest }) => {
  return !invert ? verticalProps(rest) : horizontalProps(rest);
};

const spaceUnit = 1;

export const Space = styled.div<{ size: number }>(({ size = 1 }) => ({
  ...fixedHeight(spaceUnit * size),
  ...fixedWidth(spaceUnit * size),
}));

type PublicCommonProps = Omit<CommonProps, 'isWrap' | 'invert'> & {
  wrap?: boolean;
  invert?: boolean;
} & Record<string, any>; // & BoxProps;

export const HorizontalComponent = styled(OriginalBox)<CommonProps>(getHorizontalProps);
export const Horizontal: React.FC<PublicCommonProps> = forwardRef<any, PublicCommonProps>(
  ({ wrap, ...props }, ref) => <HorizontalComponent isWrap={wrap} {...(props as any)} ref={ref} />
);

export const VerticalComponent = styled(OriginalBox)<CommonProps>(getVerticalProps);
export const Vertical: React.FC<PublicCommonProps> = forwardRef<any, PublicCommonProps>(
  ({ wrap, ...props }, ref) => <VerticalComponent isWrap={wrap} {...(props as any)} ref={ref} />
);

export const Grid = styled(OriginalBox)<gridProps>(grid);
