import React from 'react';
import { LinkButton } from 'chakra-next-link';

import { isDev } from 'app/config/is-dev';
import { useIsAdmin } from 'app/hooks/hooks';
import { useCurrentUser } from 'app/hooks/useCurrentUser';
import DevMenu from 'app/components/DevMenu';
import UserMenu from 'app/components/UserMenu';
import { useRouter } from 'next/router';
// import DarkModeSwitch from 'app/components/DarkModeSwitch';

export const DesktopHeaderItems = ({ showAuth }) => {
  const user = useCurrentUser();
  const isAdmin = useIsAdmin();
  const showDevMenu = isDev && isAdmin;

  const router = useRouter();

  return (
    <>
      {showDevMenu && <DevMenu />}
      {user && <UserMenu />}
      {!user && showAuth && (
        <>
          {/* <DarkModeSwitch /> */}
          <LinkButton href={`/login?returnUrl=${router.asPath}`} size="sm">
            Login
          </LinkButton>
          <LinkButton href={`/signup?returnUrl=${router.asPath}`} size="sm">
            Sign up
          </LinkButton>
        </>
      )}
    </>
  );
};
