import React, { Suspense, useEffect } from 'react';
import { useQueryErrorResetBoundary } from '@blitzjs/rpc';
import { ErrorBoundary } from '@blitzjs/next';
import { ConfirmContextProvider } from 'chakra-confirm';
import { ColorMode } from '@chakra-ui/react';
import SuperJSON from 'superjson';
import Head from 'next/head';

import { withBlitz } from 'app/blitz-client';
import { ChildApp } from 'app/root/ChildApp';
import { RootErrorFallback } from 'app/root/RootErrorFallback';
import { useClientQueryParams } from 'app/root/use-client-query-params';
import { MediaQueries } from 'app/components/MediaQueries';
import { appTitle } from 'app/config/appName';
import 'app/root/use-create-store'; //don't remove, must be imported here first
import { AppProps, PageTheme } from 'app/types/page-types';
import { ChangelogSlugUniqueError } from 'app/data/changelogs/error/ChangelogSlugUniqueError';
import ChakraThemeWrapper from 'app/root/ChakraThemeWrapper';

SuperJSON.registerClass(ChangelogSlugUniqueError);

//css
import 'react-mde/lib/styles/css/react-mde-all.css';
import 'react-medium-image-zoom/dist/styles.css';
import 'app/styles/tailwind.css';

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  const { getLayout = (page) => page, settings = {} } = Component;

  let forcedTheme: ColorMode = 'dark';

  const { pageTheme, overrideDarkBackground, overrideLightBackground } = settings;
  const forceThemeFromParam = pageTheme === PageTheme.FromQueryParam;
  const queryParams = useClientQueryParams();

  //when we want a forced theme from query param, we have to wait for the query params to be an object
  if (forceThemeFromParam && !queryParams) return null;

  if (forceThemeFromParam) forcedTheme = queryParams.theme || 'dark';
  if (pageTheme === PageTheme.Dark) forcedTheme = 'dark';
  if (pageTheme === PageTheme.Light) forcedTheme = 'light';

  return (
    <MediaQueries>
      <ErrorBoundary
        FallbackComponent={RootErrorFallback}
        onReset={useQueryErrorResetBoundary().reset}
      >
        <ChakraThemeWrapper
          darkBackground={overrideDarkBackground}
          lightBackground={overrideLightBackground}
          forcedTheme={forcedTheme}
        >
          <ConfirmContextProvider
            defaults={{
              delete: {
                body: 'Are you sure you want to delete this?',
              },
              cancel: 'Cancel',
            }}
          >
            <Suspense fallback={null}>
              <Head>
                <title>{appTitle()}</title>
              </Head>
              <ChildApp>{getLayout(<Component {...pageProps} />)}</ChildApp>
            </Suspense>
          </ConfirmContextProvider>
        </ChakraThemeWrapper>
      </ErrorBoundary>
    </MediaQueries>
  );
};

export default withBlitz(App);
