import SuperJSON from 'superjson';

export class ChangelogSlugUniqueError extends Error {
  entity: 'changelog' | 'changelogDraft';

  constructor(entity: 'changelog' | 'changelogDraft') {
    super(`${entity} slug must be unique`);
    this.name = 'ChangelogSlugUniqueError';
    this.entity = entity;
  }
}

SuperJSON.registerClass(ChangelogSlugUniqueError, { identifier: 'ChangelogSlugUniqueError' });
