import { getQueryClient } from '@blitzjs/rpc';
import { useMutation } from '@blitzjs/rpc';
import { useRouter } from 'next/router';
import { useDisclosure } from '@chakra-ui/react';

import logout from 'app/data/auth/mutations/logout';
import { useCurrentUserQuery } from 'app/hooks/useCurrentUser';

export const useCreateStore = () => {
  const { push } = useRouter();
  const authDisclosure = useDisclosure();
  const auth = useCurrentUserQuery(false);

  const [logoutMutation] = useMutation(logout);

  const logOutGoHome = async () => {
    await push('/');
    await logoutMutation();
    await getQueryClient().resetQueries();
  };

  const openAuthDialog = () => {
    authDisclosure.onOpen();
  };

  return {
    authDisclosure,
    openAuthDialog,
    auth,
    logOutGoHome,
  };
};
