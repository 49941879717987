import { defaultQueryOptions } from 'app/config/default-query-options';

import getCurrentUser from 'app/data/auth/queries/getCurrentUser';
import { useMagicalQuery } from 'app/utils/blitz-utils';

export const useCurrentUser = (suspense: boolean = true) => {
  const [userWithComputedData] = useMagicalQuery(getCurrentUser, undefined, {
    ...defaultQueryOptions,
    suspense,
  });
  return userWithComputedData;
};

export type CurrentUser = ReturnType<typeof useCurrentUser>;

export const useCurrentUserQuery = (suspense: boolean = true) => {
  return useMagicalQuery(getCurrentUser, undefined, { ...defaultQueryOptions, suspense });
};
