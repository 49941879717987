import React from 'react';
import { Text } from '@chakra-ui/react';
import NotificationFooter from './NotificationFooter';
import * as L from 'app/styles/layout-styled-components';

const NoAction = ({ notification, meta }) => {
  const { message } = meta;

  return (
    <L.Vertical fullW flex={1}>
      <Text flex={1}>{message}</Text>
      <NotificationFooter notification={notification} />
    </L.Vertical>
  );
};

export default NoAction;
