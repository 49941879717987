import React from 'react';
import * as L from 'app/styles/layout-styled-components';
import { Spinner } from '@chakra-ui/react';

const LoadingScreen = () => {
  return (
    <L.Vertical center flex={1} full>
      <Spinner size="xl" />
    </L.Vertical>
  );
};

export default LoadingScreen;
