import Head from 'next/head';
import React from 'react';
import { appName, appDomain, metaImage, appTitle } from 'app/config/appName';
import { getMetaImage } from 'app/styles/meta-image';

const baseUrl = `https://${appDomain}/`;

type MetaTagsProps = {
  description?: string;
  title?: string;
  imageUrl?: string;
  urlPath?: string;
};

const MetaTags: React.FC<MetaTagsProps> = (props) => {
  const {
    description = `${appName} - Keep your users in the loop`,
    title = appName,
    // imageUrl = metaImage,
    imageUrl = getMetaImage({
      preset: 'netlify',
      title: title,
      gradientColors: ['#412593', '#24145b', '#10062c'],
    }),
    urlPath = '',
  } = props;

  const url = baseUrl + urlPath;

  return (
    <Head>
      <title>{appTitle(title)}</title>
      <link rel="icon" href="/favicon.ico" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={imageUrl} />
    </Head>
  );
};

export default MetaTags;
