import { NotificationEvent } from 'app/utils/enums';

export const handleRequestEntity = ({ request, user, event }) => {
  const requestEvents = [
    { event: NotificationEvent.RequestApproved, verb: 'approved' },
    { event: NotificationEvent.RequestClosed, verb: 'closed' },
    { event: NotificationEvent.RequestLocked, verb: 'locked' },
    { event: NotificationEvent.RequestReopened, verb: 'reopened' },
    { event: NotificationEvent.RequestUnlocked, verb: 'unlocked' },
    { event: NotificationEvent.RequestUpdated, verb: 'updated' },
    { event: NotificationEvent.RequestComment, verb: 'commented on' },
  ];

  const foundEvent = requestEvents.find((r) => r.event === event);

  if (foundEvent) {
    return {
      message: `${user?.name || 'Someone'} ${foundEvent.verb} your request "${request.title}"`,
      href: `/requests/${request.id}`,
    };
  }

  return null;
};
