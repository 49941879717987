import { NotificationEvent } from 'app/utils/enums';

export const handleDiscussionEntity = ({ discussion, notification, user, event }) => {
  const discussionEvents = [
    { event: NotificationEvent.DiscussionNew, action: `posted on the forum "${discussion.title}"` },
    { event: NotificationEvent.DiscussionReply, action: `replied to your forum post` },
    {
      event: NotificationEvent.DiscussionWatching,
      action: `posted in "${discussion.title}" thread`,
    },
  ];

  const foundEvent = discussionEvents.find((r) => r.event === event);
  if (foundEvent) {
    return {
      message: `${user?.name || 'Someone'} ${foundEvent.action}`,
      href:
        notification.url ||
        `/${notification.project.creator.username}/${notification.project.slug}/forum/${discussion.id}`,
    };
  }

  return null;
};
