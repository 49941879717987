import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useNotifications } from 'app/components/NotificationsPanel/NotificationsManager';

const NotificationPopupModal = () => {
  const { notification, modalDisclosure } = useNotifications();
  const { isOpen, onClose } = modalDisclosure;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Notification</ModalHeader>
        <ModalCloseButton />
        {notification && <ModalBody>{notification?.longContent}</ModalBody>}
        <ModalFooter>
          <Button colorScheme="purple" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NotificationPopupModal;
