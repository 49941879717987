import { extendTheme } from '@chakra-ui/react';

export const customTheme = extendTheme({
  colors: {
    transparent: 'transparent',
    black: '#000',
    white: '#fff',
    accent: '#9e7aea',
  },
});
