import { capitalize } from 'lodash';

export const has = (a) => a?.length > 0;
export const onEnter = (fn) => {
  return {
    onKeyDown: (e) => {
      if (!e.shiftKey && e.key === 'Enter') {
        fn?.();
      }
    },
  };
};

export const typedCapitalize = <T extends string>(str: T): Capitalize<Lowercase<T>> => {
  return capitalize(str) as Capitalize<Lowercase<T>>;
};

export const compareLowercase = (a: string | undefined | null = '', b = '') =>
  a?.toLowerCase()?.trim().includes(b?.toLowerCase()?.trim());

export const escapeForRegex = (value: string) => value.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

export const stopPropagation = (func: () => Promise<any> | any) => {
  return (event: React.ChangeEvent | React.FormEvent) => {
    event.stopPropagation();
    func();
  };
};

export const oKeys = <T extends {}>(obj: T): (keyof T)[] => {
  return Object.keys(obj) as (keyof T)[];
};

export const oFromEntries = <T extends string, K>(arr: [T, K][]): Record<T, K> => {
  return Object.fromEntries(arr) as Record<T, K>;
};
