import React from 'react';
import { MdCheckCircle } from 'react-icons/md';
import { IconProps, ListIcon, ListItem, ListItemProps } from '@chakra-ui/react';
import * as L from 'chakra-layout-components';

type ListItemIconProps = {
  itemProps?: ListItemProps;
  iconProps?: Partial<Omit<IconProps, 'css'>>;
};

export const ListItemIcon: React.FC<ListItemIconProps> = ({ children, itemProps, iconProps }) => {
  return (
    <ListItem {...itemProps}>
      <ListIcon as={MdCheckCircle} color="purple.500" {...iconProps} />
      {children}
    </ListItem>
  );
};

export const AnimateShow = ({ show, children, visibleOpacity = 1, vertical = true, ...rest }) => {
  const property = vertical ? 'height' : 'width';
  return (
    <L.MVertical
      overflow="hidden"
      opacity={0}
      {...{ [property]: 0 }}
      animate={{
        ...(show
          ? {
              [property]: 'auto',
              opacity: visibleOpacity,
            }
          : { opacity: 0, [property]: 0 }),
      }}
      {...rest}
    >
      {children}
    </L.MVertical>
  );
};
