import { useQuery } from '@blitzjs/rpc';
import { AsyncFunc, FirstParam } from 'blitz';
import { UseQueryOptions } from '@tanstack/react-query';

import { defaultQueryOptions } from 'app/config/default-query-options';
import { AwaitReturn } from 'app/types/util';
import { RestQueryResult } from 'app/utils/magic/types';

type WithUndefined<T, Options extends Partial<{ suspense: unknown }>> = Options extends {
  suspense: false;
}
  ? T | undefined
  : T;

export const useMagicalQuery = <
  T extends AsyncFunc,
  TResult extends AwaitReturn<T>,
  Options extends UseQueryOptions<TResult, unknown, TResult>
>(
  queryFn: T,
  params: FirstParam<T>,
  options?: Options
): [WithUndefined<TResult, Options>, RestQueryResult<TResult, unknown>] => {
  return useQuery(queryFn, params, { enabled: true, ...defaultQueryOptions, ...options }) as any;
};
