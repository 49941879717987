import React from 'react';
import { ChakraProvider, ColorMode, DarkMode, LightMode, StorageManager } from '@chakra-ui/react';
import Conditional from 'conditional-wrap';

import { customTheme } from 'app/styles/theme';
import GlobalStyles from 'app/root/GlobalStyles';

type ChakraThemeWrapperProps = {
  darkBackground?: string;
  lightBackground?: string;
  forcedTheme?: ColorMode;
};

const ChakraThemeWrapper: React.FC<ChakraThemeWrapperProps> = (props) => {
  const { children, darkBackground = '#1b202b', lightBackground = '#ffffff', forcedTheme } = props;
  const isDark = forcedTheme === 'dark';
  const chakraTheme = {
    ...customTheme,
    ...(forcedTheme && {
      config: {
        initialColorMode: forcedTheme,
      },
    }),
  };

  const forcedColorModeManager: StorageManager = {
    set(_value: ColorMode) {},
    get(_init?: ColorMode): ColorMode {
      return forcedTheme || 'dark';
    },
    type: 'cookie',
  };

  return (
    <ChakraProvider resetCSS theme={chakraTheme} colorModeManager={forcedColorModeManager}>
      <Conditional
        condition={!!forcedTheme}
        wrap={(c) => {
          const Mode = isDark ? DarkMode : LightMode;
          return <Mode children={c} />;
        }}
      >
        <>
          <GlobalStyles lightBackground={lightBackground} darkBackground={darkBackground} />
          {children}
        </>
      </Conditional>
    </ChakraProvider>
  );
};

export default ChakraThemeWrapper;
