import React from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import * as L from 'chakra-layout-components';

import { useGlobalStore } from 'app/config/global-store';
import Modal from 'app/components/Modal';
import LoginForm from 'app/data/auth/components/LoginForm';
import SignupForm from 'app/data/auth/components/SignupForm';
import flex from 'app/styles/flex';

const AuthModal = () => {
  const { authDisclosure } = useGlobalStore();

  const onSignupSuccess = () => {
    authDisclosure.onClose();
  };

  const tabStyles = {
    ...flex.horizontal,
    ...flex.centerHorizontal,
    width: '100%',
  };

  return (
    <Modal showClose={false} disclosure={authDisclosure}>
      <L.Vertical fullW fullH center>
        <Tabs colorScheme="purple" align="center" width="100%">
          <TabList>
            <Tab>Register</Tab>
            <Tab>Login</Tab>
          </TabList>

          <TabPanels {...flex.center} {...flex.horizontal} mt={5} width="100%">
            <TabPanel {...tabStyles}>
              <SignupForm onSuccess={onSignupSuccess} />
            </TabPanel>
            <TabPanel {...tabStyles}>
              <LoginForm
                shouldRefetch={true}
                showHeader={false}
                showSignUp={false}
                onSuccess={onSignupSuccess}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </L.Vertical>
    </Modal>
  );
};

export default AuthModal;
