import React, { useContext, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';

const NotificationsContext = React.createContext<any>(null);
export const useNotifications = () => useContext(NotificationsContext);

const NotificationsManager = ({ children, count, refetchSeen }) => {
  const popoverDisclosure = useDisclosure();
  const modalDisclosure = useDisclosure();
  const [notification, setNotification] = useState<any>();

  const openPopup = ({ notification }) => {
    popoverDisclosure.onClose();
    setNotification(notification);
    modalDisclosure.onOpen();
  };

  return (
    <NotificationsContext.Provider
      value={{
        popoverDisclosure,
        modalDisclosure,
        notification,
        openPopup,
        refetchSeen,
        count,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsManager;
