import { useColorMode } from '@chakra-ui/react';
import { ColorMode } from 'app/utils/enums';
import React, { CSSProperties } from 'react';
import * as L from 'app/styles/layout-styled-components';

const CARD_COLORS = {
  [ColorMode.Dark]: {
    background: '#2b2e3b',
    backgroundHover: '#353947',
  },
  [ColorMode.Light]: {
    background: 'gray.100',
    backgroundHover: 'gray.300',
  },
};
export const useCardColors = () => {
  const { colorMode } = useColorMode();
  return CARD_COLORS[colorMode];
};

export const hoverableStyles = (backgroundHover: string, _hover?: CSSProperties) => ({
  cursor: 'pointer',
  _hover: {
    ..._hover,
    transition: 'all 100ms linear',
    backgroundColor: backgroundHover,
  },
});

const Card: React.FC<
  {
    onClick?: any;
    hover?: boolean;
    horizontal?: boolean;
    _hover?: any;
  } & any
> = ({ children, horizontal, _hover, onClick, hover = true, ...rest }) => {
  const applyHoverStyles = hover || !!onClick;
  const style = useCardColors();

  return React.createElement(
    horizontal ? L.Horizontal : L.Vertical,
    {
      ...(applyHoverStyles && { ...hoverableStyles(style.backgroundHover, _hover) }),
      onClick,
      width: '100%',
      p: 4,
      borderRadius: 4,
      boxShadow: 'md',
      backgroundColor: style.background,
      ...rest,
    },
    children
  );
};

export default Card;
