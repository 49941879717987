import { AppProps as BlitzAppProps } from '@blitzjs/next';
import { NextPage, NextComponentType } from 'next';

export enum PageTheme {
  Dark,
  Light,
  FromQueryParam,
}

export interface PageConfig {
  getLayout?: (component: JSX.Element) => JSX.Element;
  settings?: {
    pageTheme?: PageTheme;
    overrideDarkBackground?: string;
    overrideLightBackground?: string;
  };
}

export interface AppProps extends BlitzAppProps {
  Component: NextComponentType & PageConfig;
}

export declare type BlitzPage<P = {}, IP = P> = NextPage<P, IP> & PageConfig;
