import { humanTime } from 'app/utils/dayjs-utils';
import React from 'react';
import { Text } from '@chakra-ui/react';

const NotificationFooter = ({ notification }) => {
  const { createdAt } = notification;
  return (
    <Text opacity={0.7} fontSize="12px">
      {humanTime(createdAt)}
    </Text>
  );
};

export default NotificationFooter;
