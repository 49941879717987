import React from 'react';
import {
  Modal as ChakraModal,
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useBoolean,
  Progress,
  ModalProps,
  UseDisclosureReturn,
  Heading,
} from '@chakra-ui/react';
import Conditional from 'conditional-wrap';
import * as L from 'app/styles/layout-styled-components';

type ModalComponentProps = {
  button?: React.FC<{ onClick: () => void }>;
  saveButton?: {
    children?: React.ReactNode;
    isDisabled?: boolean;
    onClick?: () => Promise<unknown> | unknown;
    showProgress?: boolean;
  };
  onOpenEvent?: () => any;
  onCloseEvent?: () => any;
  size?: ModalProps['size'];
  disclosure?: UseDisclosureReturn;
  showClose?: boolean;
  leftFooter?: React.ReactNode;
  modalProps?: Partial<ModalProps>;
  extraButtons?: React.ReactNode | React.FC<{ onClick?: () => void }>;
  asForm?: boolean;
  isCloseDisabled?: boolean;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => Promise<unknown> | unknown;
  title?: string;
};

export const Modal: React.FC<ModalComponentProps> = ({
  children,
  showClose = true,
  disclosure,
  leftFooter,
  modalProps,
  size = 'lg',
  onOpenEvent,
  onCloseEvent,
  saveButton,
  button,
  extraButtons,
  asForm,
  onSubmit,
  isCloseDisabled,
  title,
}) => {
  const internalState = useDisclosure();
  const state = disclosure || internalState;

  const [isLoading, { on, off }] = useBoolean(false);
  const { isOpen, onOpen, onClose } = state;

  const onClick = () => {
    onOpen();
    onOpenEvent?.();
  };

  const close = () => {
    onClose();
    onCloseEvent?.();
  };

  return (
    <>
      {button?.({ onClick })}
      <ChakraModal size={size} isOpen={isOpen} onClose={close} {...modalProps}>
        <ModalOverlay />
        <ModalContent {...(asForm && { as: 'form', onSubmit })}>
          <ModalHeader>
            <Heading size="lg">{title}</Heading>
          </ModalHeader>
          <ModalCloseButton isDisabled={isLoading} />
          <ModalBody>{isOpen && children}</ModalBody>

          <ModalFooter>
            <L.Vertical w="100%" spacing={16}>
              <L.Horizontal spaceBetween w="100%">
                {leftFooter || <div></div>}
                <L.Horizontal fullH centerV spacing={15}>
                  {showClose && (
                    <Button
                      isDisabled={isLoading || isCloseDisabled}
                      colorScheme="gray"
                      onClick={close}
                    >
                      Close
                    </Button>
                  )}
                  {saveButton && (
                    <Button
                      isLoading={isLoading}
                      colorScheme="purple"
                      {...saveButton}
                      onClick={async () => {
                        const res = saveButton.onClick?.();
                        if (saveButton.showProgress) {
                          on();
                          await res;
                          off();
                        }

                        close();
                      }}
                    />
                  )}
                  {typeof extraButtons === 'function'
                    ? extraButtons?.({ onClick: close })
                    : extraButtons}
                </L.Horizontal>
              </L.Horizontal>
            </L.Vertical>
          </ModalFooter>
        </ModalContent>
      </ChakraModal>
    </>
  );
};

export default Modal;
