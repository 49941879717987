import { useGlobalStore } from 'app/config/global-store';
import { Role } from 'app/utils/enums';
import { useEffect, useRef, useState } from 'react';
import slugify from 'slugify';

export const useSlugifyInputs = (input1, input2) =>
  useEffect(() => {
    let lowercased = input1.value?.toLowerCase();
    input2.setValue(
      slugify(lowercased, {
        remove: /\./,
        strict: true,
      })
    );
  }, [input1.value]);

export default function useWindowDimensions() {
  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
}

export const usePreventLeave = () => {
  useEffect(() => {
    let listener = 'beforeunload';
    const onLeave = async (e) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener(listener, onLeave);
    return () => window.removeEventListener(listener, onLeave);
  }, []);
};

type SearchSelect = {
  bind: {
    onChange: (v: any) => any;
    value: any;
  };
  clear: () => any;
  value: any;
};

export const useSearchSelect = (): SearchSelect => {
  const [value, setValue] = useState<any>();

  return {
    bind: {
      onChange: (item) => {
        setValue(item);
      },
      value,
    },
    value,
    clear: () => setValue(null),
  };
};

export const useIsAdmin = () => {
  const { auth } = useGlobalStore();
  const [user] = auth;
  return user?.role === Role.Admin;
};

export const useScrollToHash = () => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.hash) {
      document.querySelector(window.location.hash)?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
};

export const useScrollElementToBottom = () => {
  const ref = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (ref?.current) {
      ref.current.scrollTop = 999999;
    }
  };

  return { ref, scrollToBottom };
};
