import {
  FormControl,
  FormControlProps,
  FormErrorIcon,
  FormLabel,
  Input,
  InputProps,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from '@chakra-ui/react';
import * as L from 'chakra-layout-components';
import { forwardRef, PropsWithoutRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { AnimateShow } from 'app/styles/chakra-components';

export interface LabeledTextFieldProps extends PropsWithoutRef<JSX.IntrinsicElements['input']> {
  name: string;
  label: string;
  outerProps?: any;
  controlProps?: FormControlProps;
}

type FinalProps = LabeledTextFieldProps & Partial<InputProps>;

export const LabeledTextField = forwardRef<HTMLInputElement, FinalProps>(
  ({ label, outerProps, type, controlProps, ...props }) => {
    const {
      register,
      formState: { isSubmitting, errors },
    } = useFormContext();

    const { name } = props;
    const error = Array.isArray(errors[name])
      ? errors[name].join(', ')
      : errors[name]?.message || errors[name];

    return (
      <FormControl width="100%" {...controlProps} isInvalid={!!error}>
        <FormLabel opacity={0.7} htmlFor={name}>
          {label}
        </FormLabel>
        {type === 'number' && (
          <NumberInput id={name} isInvalid={!!error} isDisabled={isSubmitting}>
            <NumberInputField {...register(name, { valueAsNumber: true })} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        )}
        {type !== 'number' && (
          <Input
            id={name}
            isInvalid={!!error}
            isDisabled={isSubmitting}
            type={type}
            {...props}
            {...register(name)}
          />
        )}

        <AnimateShow fullW visibleOpacity={0.85} show={!!error}>
          <L.Horizontal fullW centerV pt={3} spacing={8}>
            <FormErrorIcon color="red.300" />
            <Text width="100%" fontSize="sm" color="red.300">
              {error}
            </Text>
          </L.Horizontal>
        </AnimateShow>
      </FormControl>
    );
  }
);
