import { AuthenticationError, AuthorizationError } from 'blitz';
import { useMutation } from '@blitzjs/rpc';
import { useToast } from '@chakra-ui/react';
import { UseMutationOptions } from '@tanstack/react-query';
import { useConfirmDelete } from 'chakra-confirm';

import { useGlobalStore } from 'app/config/global-store';

type CustomOptions<T> = {
  showLoginOnFail?: boolean | ((s: any) => boolean);
  showErrorToast?: boolean;

  errorMessage?: string;
  successToastMessage?: string;
  onFail?: (res: T) => Promise<unknown> | void;
  onWin?: (res: T) => Promise<unknown> | void;

  shouldConfirmDelete?: boolean;
  throwOnError?: boolean;
};

type MutationFunction<TData, TVariables = unknown> = (
  variables: TVariables,
  ctx?: any
) => Promise<TData>;

export const useMagicalMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  c: MutationFunction<TData, TVariables>,
  options: CustomOptions<TData> & UseMutationOptions<TData, TError, TVariables, TContext> = {
    throwOnError: true,
  }
) => {
  const { openAuthDialog } = useGlobalStore();
  const {
    errorMessage,
    showLoginOnFail,
    showErrorToast = true,
    successToastMessage: successToastMessage,
    onFail,
    onWin,
    shouldConfirmDelete,
    ...restOfOptions
  } = options;

  const confirmDelete = useConfirmDelete();
  const toast = useToast();

  const showError = (title = 'An error occurred') => {
    toast({
      title: title,
      status: 'error',
      isClosable: true,
      position: 'top-right',
    });
  };

  const [mut, other] = useMutation(c, restOfOptions);

  const fn = async (v: TVariables, a?: any): Promise<TData | undefined> => {
    try {
      if (shouldConfirmDelete && !(await confirmDelete())) {
        return undefined;
      }

      const data = await mut(v, a);
      if (data !== null && data !== undefined) {
        onWin?.(data);
        if (successToastMessage) {
          toast({
            title: successToastMessage,
            status: 'success',
            duration: 1000,
            isClosable: true,
            position: 'top-right',
          });
        }
        return data;
      }
    } catch (err) {
      // TODO: Handle Authorization as a separate modal (similar to django "hey you need a different user for this")
      const isAuthError = err instanceof AuthenticationError || err instanceof AuthorizationError;
      const shouldShowLoginOnFail =
        typeof showLoginOnFail === 'boolean'
          ? showLoginOnFail && isAuthError
          : showLoginOnFail?.(err);

      if (showErrorToast && !shouldShowLoginOnFail) {
        showError(errorMessage || `Error: ${err?.message}`);
      }
      if (shouldShowLoginOnFail) {
        openAuthDialog();
      }
      onFail?.(err);
    }
  };

  return [fn, other] as const;
};
