import React, { Suspense } from 'react';
import { Menu, MenuButton, MenuDivider, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { Link } from 'chakra-next-link';
import * as L from 'chakra-layout-components';

import { useIsAdmin } from 'app/hooks/hooks';
import { useGlobalStore } from 'app/config/global-store';
import { UserAvatar } from 'app/components/UserAvatar';
import { getGuard, useAbilities } from 'app/guard/client';
import ProjectListMenu from 'app/components/UserMenu/ProjectListMenu';

const ShowDeploymentLink = () => {
  const [seeDeployment] = useAbilities([getGuard('deployment', 'see', undefined as never)]);
  if (!seeDeployment.can) return null;

  return (
    <Link href="/deployment" justLink>
      <MenuItem w="100%">Deployment</MenuItem>
    </Link>
  );
};

const UserMenu = () => {
  const { auth, logOutGoHome } = useGlobalStore();

  const [user] = auth;
  const isAdmin = useIsAdmin();

  // const { colorMode, toggleColorMode } = useColorMode();
  // const dark = colorMode === 'dark';

  return (
    <Menu>
      <MenuButton _hover={{ opacity: 1 }} opacity={0.85}>
        {user && <UserAvatar user={user} avatarProps={{ size: 'md', cursor: 'pointer' }} />}
      </MenuButton>

      <MenuList>
        {user && (
          <>
            <L.Horizontal centerV spacing={10} py={3} px={5}>
              <Text>
                Signed in as <b>{user?.name}</b>
              </Text>
            </L.Horizontal>
            <MenuDivider />
          </>
        )}

        {user && <ProjectListMenu />}

        {/* <MenuItem icon={dark ? <MoonIcon /> : <SunIcon />} onClick={(e) => toggleColorMode()}>
          <Text>{dark ? 'Dark' : 'Light'} theme</Text>
        </MenuItem> */}
        <Link href="/settings" justLink>
          <MenuItem w="100%">Settings</MenuItem>
        </Link>
        {isAdmin && (
          <Link href="/creators" justLink>
            <MenuItem w="100%">Creators</MenuItem>
          </Link>
        )}
        <Suspense fallback={null}>
          <ShowDeploymentLink />
        </Suspense>
        <MenuItem onClick={logOutGoHome}>Sign out</MenuItem>
        {!user && (
          <MenuItem>
            <Link noUnderline w="100%" href="/login">
              Login
            </Link>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};

export default UserMenu;
