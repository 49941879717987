import React, { Suspense } from 'react';
import { headerHeight } from 'app/components/Header';
import LoadingScreen from 'app/components/LoadingScreen';
import { ZIndexes } from 'app/config/z-indexes';
import { LayoutProps } from 'app/layouts/types';
import { Grid } from 'app/styles/layout-styled-components';

export const LayoutInside: React.FC<Partial<LayoutProps>> = ({
  children,
  spacingHeaderContent = 15,
  maxWidth,
  layoutProps,
  contentPadding,
  showHeader,
}) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Grid
        className=""
        {...(showHeader && {
          mt: `${headerHeight + spacingHeaderContent}px`,
        })}
        id="layout"
        alignContent="flex-start"
        maxWidth={maxWidth}
        zIndex={ZIndexes.Layout}
        py={2}
        width="100%"
        px={[contentPadding, contentPadding, contentPadding, contentPadding, 0]}
        {...layoutProps}
      >
        {children}
      </Grid>
    </Suspense>
  );
};

export default LayoutInside;
