import { CreateStylesDebugger } from 'styles-debugger';
export const isInBrowser = typeof window !== 'undefined';

//@ts-ignore
export const sizzyObject = isInBrowser && window?.__sizzy;

const on = false;
const enabled = on && !!sizzyObject;

export const debugStyle = CreateStylesDebugger({
  //@ts-ignore
  enabled,
  borderSize: 1,
  position: 1,
  styles: {
    text: {
      color: 'red',
    },
  },
  debugWith: 'border',
});
