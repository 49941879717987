import React from 'react';
import { FiBell } from 'react-icons/fi';
import {
  Box,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';

import markAllNotificationsSeen from 'app/data/notifications/mutations/markAllNotificationsSeen';
import getUserNotifications from 'app/data/notifications/queries/getUserNotifications';
import { useNotifications } from 'app/components/NotificationsPanel/NotificationsManager';
import { useMagicalMutation, useMagicalQuery } from 'app/utils/blitz-utils';
import NotificationList from 'app/components/NotificationsPanel/NotificationList';
import * as L from 'app/styles/layout-styled-components';
import { AwaitReturn } from 'app/types/util';

export type NotificationPopupType = AwaitReturn<typeof getUserNotifications>[number];

const NotificationsPopover = () => {
  const { popoverDisclosure, count, refetchSeen } = useNotifications();
  const { isOpen, onToggle, onClose } = popoverDisclosure;
  const [markAllSeenMutation] = useMagicalMutation(markAllNotificationsSeen);
  const [notifications, { isLoading, refetch }] = useMagicalQuery(getUserNotifications, {});

  const markAllSeenFn = async () => {
    await markAllSeenMutation({});
    refetchSeen();
    refetch();
  };

  const hasNotifications = count > 0;

  return (
    <Popover onOpen={markAllSeenFn} isOpen={isOpen}>
      <PopoverTrigger>
        <Box pos="relative">
          <Box onClick={onToggle} cursor="pointer" as={FiBell} boxSize="24px" color="gray.400" />
          {hasNotifications && (
            <L.Vertical
              centerV
              as="span"
              pos="absolute"
              top={0}
              right={0}
              py="2px"
              opacity={1}
              px={1}
              fontSize={12}
              borderRadius={4}
              background={`#b75e5e`}
              transform="scale(1) translate(50%, -50%)"
              transformOrigin="100% 0%"
            >
              {count}
            </L.Vertical>
          )}
        </Box>
      </PopoverTrigger>
      <PopoverContent zIndex={4}>
        <PopoverArrow />
        <PopoverCloseButton onClick={onClose} />
        <PopoverBody py={6}>
          <Heading size="sm">Notifications</Heading>
          {!isLoading && <NotificationList notifications={notifications} refetch={refetch} />}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default NotificationsPopover;
