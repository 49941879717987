import { arrayOfShame } from 'app/config/array-of-shame';
import { hallOfFame } from 'app/config/hall-of-fame';
import { isStaging, isDev } from './is-dev';

export const appName = 'Glink';
export const appDomain = isStaging ? 'staging.glink.so' : 'glink.so';
export const appSlug = 'glink';

export const appTitle = (title = appName) => {
  if (isDev) {
    return `[${isStaging ? 'STAGING' : 'DEV'}] ${title}`;
  }

  return title;
};

export let metaImage =
  'https://images.unsplash.com/photo-1556691421-cf15fe27a0b6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80';

export const contributors = [
  ...hallOfFame,
  'jotostream',
  'koenthedutch',
  'praneet',
  'centos1235',
  'Ezteq',
  'geekysrm',
  'Hjaezmvnnzpqeqjjnz',
  'cosaqueee',
  'zeko365',
  'boxxroom',
  'binvalencia',
  'aussieboi',
  'httpJunkie',
  'Lemonjuices12',
  'Alexanderkomeiji',
  'ex0o0o',
  'Anotherttvviewer',
  'Anymaniax',
  'Aten',
  'praneet',
  'Commanderroot',
  'Cosaqueee',
  'Crankymaracuja',
  'Dinu',
  'Feelsduckman',
  'Flasthem',
  'Funcdev',
  'Gabrielcolson',
  'Hallomarcus',
  'K3jru',
  'Khouby3',
  'Krunalshah',
  'kingdaro_',
  'Kypdy',
  'Lurxx',
  'Motiondeveloper',
  'Muescha',
  'Myyno97',
  'Oxylus42',
  'Panhandier',
  'Peterfile124',
  'Rimastino',
  'Rubberslayer',
  'Selfmadedev',
  'Specroot',
  'Sproettiemao',
  'Steffi128',
  'Teischente',
  'Twisnia',
  'Universe',
  'V_and_k',
  'Virgoproz',
  'Whynotjustin',
  'mciasco',
  'Zikkx',
  'Abugung',
  'DanielK_CZ',
  '1v4n01',
  'Abugung',
  'benjjjjaminn',
  'Afflekt',
  'Alexanderkomeiji',
  'Anotherttvviewer',
  'Aten',
  'Benjjjjaminn',
  'Brunowinck',
  'Chromebreaker01',
  'Cmjio',
  'Commanderroot',
  'Cosaqueee',
  'Crankymaracuja',
  'Cryopunkz',
  'Darkoe',
  'Denniske_1001',
  'Devvanleeuwen',
  'Dexter36',
  'Dhjraven',
  'Fallingq',
  'Flasthem',
  'Greenpassion',
  'Hackerm4nn',
  'Hallomarcus',
  'Hjaezmvnnzpqeqjjnz',
  'Itsvodoo',
  'Jaythedeveloper',
  'Joaofoliveira',
  'Jvictorvo',
  'Lemonjuices12',
  'Llius123',
  'Lpalmeida18',
  'Lurxx',
  'Mhmodo10',
  'Oxylus42',
  'Pharti77',
  'Rimastino',
  'Robert_jan',
  'Robinmalfait',
  'Robinxdr',
  'Rubberslayer',
  'Rudixworld',
  'Sagnurschwitzer',
  'Selfmadedev',
  'Smashboy',
  'Sproettiemao',
  'mrsimpson1111',
  'Umxr_g',
  'Umxr_g',
  'Virgoproz',
  'Zeko369',
  'Zikkx',
  'Sidou010',
  'Clempat',
  'fr3fou',
  'fr1zle',
  'sheix',
  ...arrayOfShame,
];
