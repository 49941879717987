import React from 'react';
import Footer from 'app/components/Footer';
import Header from 'app/components/Header';
import MetaTags from 'app/components/MetaTags';
import { layoutConfigs } from 'app/layouts/layout-configs';
import LayoutInside from 'app/layouts/LayoutInside';
import { NextWrapper } from 'app/layouts/NextWrapper';
import { LayoutConfig, Meta } from 'app/layouts/types';

type LayoutProps = {
  addDefaultMetaTags?: boolean;
  meta?: Meta;
  layoutConfig?: LayoutConfig;
  showAuth?: boolean;
};

export const Layout: React.FC<LayoutProps> = ({
  addDefaultMetaTags = true,
  layoutConfig = LayoutConfig.Default,
  showAuth = true,
  meta,
  children,
}) => {
  let layout = layoutConfigs[layoutConfig];

  const { showHeader, maxWidth, headerMaxWidth, overrideNext, contentPadding, showFooter } = layout;

  return (
    <NextWrapper css={overrideNext}>
      {addDefaultMetaTags && <MetaTags {...meta} />}
      {showHeader && (
        <Header
          showAuth={showAuth}
          contentPadding={contentPadding}
          maxWidth={headerMaxWidth !== null ? headerMaxWidth : maxWidth}
        />
      )}
      <LayoutInside {...layout}>{children}</LayoutInside>
      {showFooter && <Footer />}
    </NextWrapper>
  );
};

export { Page } from './Page';
export { LayoutConfig } from './types';
export default Layout;
