export const externalURL = process.env.RAILWAY_STATIC_URL;
export const railwayEnvironment = process.env.RAILWAY_ENVIRONMENT;

export const isStaging = railwayEnvironment?.toLowerCase() === "staging";
export const isProduction = railwayEnvironment?.toLowerCase() === "production";
export const isDev = !isProduction && !isStaging;

export const isRailway = !!process.env.RAILWAY_ENVIRONMENT;
export const isPullRequest = isRailway && !isStaging && !isProduction;

export const isInSizzy = typeof window !== 'undefined' && !!window['__sizzy'];
export const isFirstSizzyDevice = isInSizzy && window['__sizzy']?.device?.index === 0;
