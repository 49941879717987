import { useRouter } from 'next/router';

//root
import { useCreateStore } from 'app/root/use-create-store';
import React, { Suspense, useEffect } from 'react';
import { Spinner } from '@chakra-ui/react';
import { ReactGenieAnimations } from 'react-genie-with-emotion';
import * as Sentry from '@sentry/nextjs';

import AuthModal from 'app/components/AuthModal';
import { GlobalStoreProvider } from 'app/config/global-store';
import { appDomain } from 'app/config/appName';

export const ChildApp = ({ children }) => {
  const store = useCreateStore();
  const { route } = useRouter();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.location.host === 'fungarzione.com') {
        window.location.href = `https://${appDomain}/${window.location.pathname}`;
      }
    }
  }, []);

  const user = store.auth[0];
  useEffect(() => {
    if (typeof window !== 'undefined' && user) {
      Sentry.setUser({ id: user.id, email: user.email, username: user.name || '' });
    }
  }, [user]);

  if (route.includes('/mjml')) return children;

  return (
    <>
      <ReactGenieAnimations />
      <GlobalStoreProvider value={store}>
        <AuthModal />
        <Suspense fallback={<Spinner />}>{children}</Suspense>
      </GlobalStoreProvider>
    </>
  );
};
