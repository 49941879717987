import { GuardTree } from 'app/guard/ability';
import { useMagicalQuery } from 'app/utils/blitz-utils';
import getAbility from './queries/getAbility';

export const getGuard = <R extends keyof GuardTree, A extends keyof GuardTree[R]>(
  resource: R,
  ability: A,
  // @ts-expect-error
  args: Parameters<ReturnType<GuardTree[R][A]>>[0]
) => {
  return [resource, ability, args] as const;
};

export const useAbilitiesQuery = (args: any[], suspense: boolean = false) => {
  const opts = !suspense && {
    initialData: args.map(() => ({ can: false, reason: '' })),
    staleTime: 0,
  };

  return useMagicalQuery(getAbility, args, opts || {});
};

export const useAbilities = (args: any[], suspense: boolean = false) => {
  const [res] = useAbilitiesQuery(args, suspense);
  return res;
};
