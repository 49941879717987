import { NotificationEvent, User } from 'db';
import { NotificationEntity } from 'app/utils/enums';
import { NotificationPopupType } from 'app/components/NotificationsPanel/NotificationsPopover';
import { handleDiscussionEntity } from './handle-discussion-entity';
import { handleRequestEntity } from './handle-request-entity';

type HandleNotificationArgs = {
  entity: any;
  notification: NotificationPopupType;
  user: User;
  event: NotificationEvent;
};

type HandleNotificationRes = {
  message: string;
  href?: string;
};

export const handleNotification = (args: HandleNotificationArgs): HandleNotificationRes | null => {
  const { entity, notification, user, event } = args;
  if (!entity) return null;

  const { data, model } = entity;
  if (!data || !model) {
    return null;
  }

  switch (model) {
    case NotificationEntity.Request:
      return handleRequestEntity({ request: data, user, event });
    case NotificationEntity.Discussion:
      return handleDiscussionEntity({ discussion: data, notification, user, event });
    case NotificationEntity.Changelog: {
      // TODO: Stricter type checks
      const project = notification.project!;
      const changelog = data;

      return {
        message: `New ${project.name} changelog has been published: "${changelog.title}"`,
        href: `/${project.creator.username}/${project.slug}/changelogs/${changelog.slug}`,
      };
    }
    default:
      return null;
  }
};
