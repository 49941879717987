import React from 'react';
import { Image as ChakraImage, Box, useColorMode, HTMLChakraProps } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { ColorMode } from 'app/utils/enums';
import { maxWidthForProjectEditor } from 'app/layouts';

export const useIsDark = () => {
  const { colorMode } = useColorMode();
  return colorMode === ColorMode.Dark;
};

export const absoluteCenter = {
  position: 'absolute',
  margin: 'auto',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
} as const;

const ChakraVideo = React.forwardRef<HTMLVideoElement, HTMLChakraProps<'video'>>((props, ref) => {
  // @ts-expect-error
  return <Box as="video" ref={ref} {...props} />;
});

export const ChakraStyledVideo = styled(ChakraVideo)({ borderRadius: 5 });
export const ChakraStyledImage = styled(ChakraImage)({ borderRadius: 5, overflow: 'hidden' });

export const hoverableIcon = {
  color: 'whiteAlpha.400',
  cursor: 'pointer',
  transition: 'all 100ms linear',
  _hover: {
    color: 'whiteAlpha.700',
  },
};

export const belowEditorHeader = {
  maxWidth: maxWidthForProjectEditor,
  margin: 'auto',
};
