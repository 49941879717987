import React, { Suspense, useState } from 'react';
import { Link } from 'chakra-next-link';
import { FaHome } from 'react-icons/fa';
import { Heading, IconButton } from '@chakra-ui/react';

import { ZIndexes } from 'app/config/z-indexes';
import { CommonProps } from 'app/styles/layout-styled-components';
import { useGlobalStore } from 'app/config/global-store';
import { useIsDark } from 'app/styles/chakra-styles';
import { DesktopHeaderItems } from 'app/components/DesktopHeaderItems';
import { NotificationsPanel } from 'app/components/NotificationsPanel';
import { useClickOnTitle } from 'app/hooks/useUseless';
import * as L from 'app/styles/layout-styled-components';

export const headerHeight = 60;

type HeaderProps = {
  maxWidth?: number | string;
  contentPadding?: number | string;
  renderLeft?: any;
  showAuth?: boolean;
} & Partial<CommonProps>;

const Header: React.FC<HeaderProps> = ({
  maxWidth,
  contentPadding,
  renderLeft,
  showAuth = true,
  ...rest
}) => {
  const {
    auth: [user],
  } = useGlobalStore();
  const isDark = useIsDark();

  const [title, onClick] = useClickOnTitle();

  return (
    <L.Horizontal
      id="header"
      transition="all 100ms linear"
      boxShadow="sm"
      backgroundColor={isDark ? '#283042' : 'white'}
      position="fixed"
      center
      top={0}
      left={0}
      fullW
      p={contentPadding}
      zIndex={ZIndexes.Header}
      height={`${headerHeight}px`}
      {...rest}
    >
      <L.Horizontal maxWidth={maxWidth} centerV spacing={10} spaceBetween fullW>
        {renderLeft || (
          <Link noUnderline href="/" onClick={onClick}>
            <L.Horizontal centerV spacing={10}>
              <IconButton aria-label="go home" icon={<FaHome />} />
              <Heading size="sm">{title}</Heading>
            </L.Horizontal>
          </Link>
        )}

        <Suspense fallback={null}>
          <L.Horizontal centerV spacing={10}>
            <DesktopHeaderItems showAuth={showAuth} />
            {user && <NotificationsPanel />}
          </L.Horizontal>
        </Suspense>
      </L.Horizontal>
    </L.Horizontal>
  );
};

export default Header;
