import React from 'react';
import { Heading, Text } from '@chakra-ui/react';
import * as L from 'chakra-layout-components';

import signup from 'app/data/auth/mutations/signup';
import { Form } from 'app/components/Form';
import { useGlobalStore } from 'app/config/global-store';
import { SignupSchema } from 'app/data/auth/schemas';
import { useMagicalMutation } from 'app/utils/blitz-utils';
import { LabeledTextField } from 'app/components/forms/LabeledTextField';
import { FaGithub } from 'react-icons/fa';
import { LinkButton } from 'chakra-next-link';

type SignupFormProps = {
  onSuccess?: any;
};

export const SignupForm: React.FC<SignupFormProps> = ({ onSuccess }) => {
  const { auth } = useGlobalStore();
  const [signupMutation, { isLoading }] = useMagicalMutation(signup, {
    onWin: async (req) => {
      await auth[1]?.refetch?.();
      await onSuccess?.(req);
    },
  });

  return (
    <L.Vertical center fullW maxWidth={350} spacing={10}>
      <Heading size="md">Create an account</Heading>

      <Text textAlign="center" opacity={0.5} fontSize="12px">
        For now, you can follow projects, leave feedback, and open feature/bugfix requests. Creating
        your own projects and changelogs will be available soon.
      </Text>

      <Form
        isLoading={isLoading}
        renderSubmitSection={({ SubmitButton }) => (
          <L.Vertical spacing={10} fullW>
            <SubmitButton />
            <LinkButton href="/api/auth/github" leftIcon={<FaGithub />}>
              Sign up with GitHub
            </LinkButton>
          </L.Vertical>
        )}
        submitText="Sign up"
        schema={SignupSchema}
        onSubmit={(values) => signupMutation(values)}
      >
        <L.Vertical fullW spacing={10}>
          <LabeledTextField autoFocus={true} name="email" label="Email" placeholder="Email" />
          <LabeledTextField name="username" label="Username" placeholder="Username" />
          <LabeledTextField name="name" label="Name" placeholder="Name" />
          <LabeledTextField
            name="password"
            label="Password"
            placeholder="Password"
            type="password"
          />
        </L.Vertical>
      </Form>
    </L.Vertical>
  );
};

export default SignupForm;
