import { z } from 'zod';

const PasswordSchema = z
  .string()
  .min(8)
  .refine((v) => v?.trim() !== '', {
    message: 'Cannot be empty',
  });

const EmailSchema = z.string().email().nonempty();

export const LoginSchema = z.object({
  email: EmailSchema,
  password: PasswordSchema,
});

export const SignupSchema = z.object({
  email: EmailSchema,
  password: PasswordSchema,
  name: z.string().nonempty(),
  username: z.string().nonempty(),
});

export const ForgotPasswordSchema = z.object({
  email: EmailSchema,
});

export const ResetPasswordFormSchema = z
  .object({ password: PasswordSchema, confirm: PasswordSchema })
  .refine((v) => v.password === v.confirm, {
    message: "Passwords don't match",
    path: ['confirm'],
  });

export const ResetPasswordSchema = z.object({
  password: PasswordSchema,
  token: z.string(),
});

export const UpdateUserSchema = z.object({
  username: z.string(),
  name: z.string(),
  bio: z.string(),
});
