import { Button, FormControl, FormErrorIcon, FormErrorMessage } from '@chakra-ui/react';
import * as L from 'chakra-layout-components';
import { CommonProps } from 'chakra-layout-components';
import React, {
  ForwardedRef,
  forwardRef,
  PropsWithoutRef,
  ReactNode,
  useEffect,
  useState,
  useImperativeHandle,
} from 'react';
import { FormProvider, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';

import * as z from 'zod';

export type RenderSubmitSection = ({
  isLoading,
  submitDisabled,
  submitText,
}: {
  isLoading?: boolean;
  submitDisabled: boolean;
  submitText?: string;
  SubmitButton?: any;
}) => any;

type fn = ({ formContent }: { formContent: any; SubmitButton: any }) => any;

export interface FormProps<S extends z.ZodType<any, any>>
  extends Omit<PropsWithoutRef<JSX.IntrinsicElements['form']>, 'onSubmit'> {
  children?: ReactNode | fn;
  fields?: ReactNode;
  submitText?: string;
  schema?: S;
  onSubmit: (values: z.infer<S>) => Promise<void | OnSubmitResult>;
  initialValues?: UseFormProps<z.infer<S>>['defaultValues'];
  useFormProps?: UseFormProps;
  wrapperProps?: CommonProps;
  isLoading?: boolean;
  renderSubmitSection?: RenderSubmitSection;
  submitOnChange?: boolean;
  watchDisabled?: (val: boolean) => void;
  formCtx?: any;
}

interface OnSubmitResult {
  FORM_ERROR?: string;
  [prop: string]: any;
}

export const FORM_ERROR = 'FORM_ERROR';

const InnerForm = <S extends z.ZodType<any, any>>(
  allProps: FormProps<S>,
  ref: ForwardedRef<UseFormReturn<z.infer<S>>>
) => {
  const {
    children,
    submitText,
    schema,
    initialValues,
    onSubmit,
    isLoading,
    useFormProps,
    renderSubmitSection,
    watchDisabled,
    wrapperProps,
    fields,
    submitOnChange,
    formCtx,
    ...props
  } = allProps;
  const ctx = formCtx || useForm<z.infer<S>>({
    mode: 'onBlur',
    resolver: async (values) => {
      try {
        if (schema) {
          schema.parse(values);
        }
        return { values, errors: {} };
      } catch (error) {
        return { values: {}, errors: error.formErrors?.fieldErrors };
      }
    },
    //typeme: blitz form
    //@ts-ignore
    defaultValues: initialValues,
    ...useFormProps,
  });

  const [formError, setFormError] = useState<string | null>(null);

  const { formState } = ctx;
  const { isSubmitting, isValid } = formState;
  const submitDisabled = isSubmitting || !isValid || !!isLoading;

  useImperativeHandle(ref, () => ({ ...ctx }), [ctx]);
  useEffect(() => {
    watchDisabled?.(submitDisabled);
  }, [submitDisabled]);

  let SubmitButton: React.FC = ({ children, ...props }) => (
    <Button isLoading={isLoading} type="submit" disabled={submitDisabled} {...props}>
      {children || submitText}
    </Button>
  );

  let submitForm = ctx.handleSubmit(async (values) => {
    if(!onSubmit) return;
    const result = (await onSubmit(values)) || {};
    for (const [key, value] of Object.entries(result)) {
      if (key === FORM_ERROR) {
        setFormError(value);
      } else {
        ctx.setError(key as any, {
          type: 'submit',
          message: value,
        });
      }
    }
  });

  let formContent = (
    <L.Vertical centerV fullW alignItemsStart spacing={15} {...wrapperProps}>
      {fields || children}

      <FormControl isInvalid={!!formError}>
        <FormErrorMessage>
          <FormErrorIcon />
          {formError}
        </FormErrorMessage>
      </FormControl>

      {!renderSubmitSection && submitText && (
        <L.Horizontal fullW>
          <SubmitButton />
        </L.Horizontal>
      )}

      {renderSubmitSection?.({
        submitDisabled,
        SubmitButton,
        submitText,
        isLoading,
      })}
    </L.Vertical>
  );

  return (
    <FormProvider {...ctx}>
      <form
        onSubmit={submitForm}
        className="form"
        {...(submitOnChange && { onChange: submitForm })}
        {...props}
      >
        {typeof children === 'function' ? children({ formContent, SubmitButton }) : formContent}
      </form>
    </FormProvider>
  );
};

export const Form = forwardRef(InnerForm);
export default Form;
