/**
 * @param {string} errorName
 */
export const ignoreError = (errorName) => {
  switch (errorName) {
    case 'AuthenticationError':
    case 'EmailExistsError':
    case 'ResetPasswordError':
    case 'GenericError':
    // case 'AuthorizationError':
    case 'CSRFTokenMismatchError':
    case 'NotFoundError':
    case 'ChunkLoadError':
      return true;
  }
};
