import React from 'react';
import { css, Global } from '@emotion/react';
import { useColorModeValue } from '@chakra-ui/react';

const GlobalStyles = ({ lightBackground, darkBackground }) => {
  const backgroundColor = useColorModeValue(lightBackground, darkBackground);

  return (
    <Global
      styles={css`
        * {
          box-sizing: border-box !important;
          flex-shrink: 0;
        }

        body,
        html,
        #__next {
          min-width: 100vw;
          max-width: 100vw;
          width: 100vw;
          overflow-x: hidden;
          min-height: 100vh;
        }

        body,
        html,
        #__next,
        #layout {
          background-color: ${backgroundColor};
        }

        #header {
          // background-color: ${backgroundColor};
        }

        #__next {
          display: grid;
        }

        @supports (-webkit-touch-callout: none) {
          #__next {
            min-height: -webkit-fill-available;
            max-height: -webkit-fill-available;
          }
        }

        ::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        ::-webkit-scrollbar-track {
          background: none;
        }

        ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 4px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #555;
          border-radius: 4px;
        }
      `}
    />
  );
};

export default GlobalStyles;
