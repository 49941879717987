import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import Twemoji from 'react-twemoji';

import { getResourceUrl } from 'app/data/images/s3/config';
import { IImage } from 'app/components/ImageUploader/Preview/PreviewImages';

const ProjectLogo: React.FC<
  { size?: number } & ({ logo: IImage | null } | { emoji: string | null })
> = ({ size = 80, ...rest }) => {
  if ('logo' in rest && rest['logo']) {
    const logoUrl = getResourceUrl(rest.logo);
    return <Image borderRadius="100%" boxSize={`${size}px`} objectFit="cover" src={logoUrl} />;
  }

  if ('emoji' in rest && rest['emoji']) {
    return (
      <Twemoji noWrapper={true}>
        <Box {...rest} boxSize={`${size}px`}>
          {rest.emoji}
        </Box>
      </Twemoji>
    );
  }

  return null;
};

export default ProjectLogo;
