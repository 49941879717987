import React from 'react';
import { Link } from 'chakra-next-link';
import { Text } from '@chakra-ui/react';
import { useBoolean } from 'react-hanger';
import { shuffle, uniq } from 'lodash';

import * as L from 'app/styles/layout-styled-components';
import { contributors, appName } from 'app/config/appName';

const Footer = () => {
  const showTwitch = useBoolean(false);
  const allContributors = uniq(shuffle(contributors));

  return (
    <L.Vertical fullW py={5} center spacing={8}>
      <L.Horizontal opacity={0.65} fullW center fontSize={10} spacing={3}>
        {showTwitch.value && (
          <L.Vertical fullW lineHeight="2" spacing={15} center>
            <Link href="https://twitch.tv/thekitze">{appName} was made live on Twitch</Link>
            <L.Horizontal center spacing={5} fullW wrap>
              {allContributors.map((c) => (
                <Text key={c} textAlign="center">
                  {c}
                </Text>
              ))}
            </L.Horizontal>
          </L.Vertical>
        )}
      </L.Horizontal>
    </L.Vertical>
  );
};

export default Footer;
