import React from 'react';
import { map } from 'lodash';
import { Menu, MenuGroup, MenuButton, MenuList, MenuItem, Button } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

import forceNotification from 'app/data/dev/mutations/forceNotification';
import sendFakeEmail from 'app/data/dev/mutations/sendFakeEmail';
import { useMagicalMutation } from 'app/utils/blitz-utils';
import { ForcedNotificationType } from 'app/data/dev/enums';

const DevMenu = () => {
  const [forceNotificationMutation] = useMagicalMutation(forceNotification);
  const [sendFakeEmailMutation] = useMagicalMutation(sendFakeEmail);

  const sendFakeEmailFn = async () => {
    await sendFakeEmailMutation({});
  };

  return (
    <Menu>
      <MenuButton rightIcon={<ChevronDownIcon />} as={Button}>
        Dev
      </MenuButton>
      <MenuList>
        <MenuGroup>
          <MenuItem onClick={sendFakeEmailFn}>Send fake email</MenuItem>
          {map(ForcedNotificationType, (type) => (
            <MenuItem key={type} onClick={() => forceNotificationMutation({ type })}>
              Push {type} Notification
            </MenuItem>
          ))}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};

export default DevMenu;
