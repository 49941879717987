import { createContext, useContext } from 'react';
import useMedia from 'use-media';

export const mqStrings = {
  maxWidth: (width) => `(max-width: ${width}px)`,
  minWidth: (width) => `(min-width: ${width}px)`,
};

export const makeQuery = (query) => `@media ${query}`;

export const smaller = (width, styles) => ({
  [makeQuery(mqStrings.maxWidth(width))]: styles,
});

export const bigger = (width, styles) => ({
  [makeQuery(mqStrings.minWidth(width))]: styles,
});

const defaultValues = { isMedium: false, isMobile: false, isTiny: false };

export const MediaQueryContext = createContext<{
  isMedium: boolean;
  isMobile: boolean;
  isTiny: boolean;
}>(defaultValues);

export const useMediaQueries = () => useContext(MediaQueryContext);

export const mediaQueries = {
  medium: mqStrings.maxWidth(850),
  mobile: mqStrings.maxWidth(430),
  tiny: mqStrings.maxWidth(320),
};

export const MediaQueries = ({ children }) => {
  const isMedium = useMedia(mediaQueries.medium);
  const isMobile = useMedia(mediaQueries.mobile);
  const isTiny = useMedia(mediaQueries.tiny);

  return (
    <MediaQueryContext.Provider
      value={{
        isMedium,
        isMobile,
        isTiny,
      }}
    >
      {children}
    </MediaQueryContext.Provider>
  );
};
