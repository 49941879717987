import NotificationsManager from 'app/components/NotificationsPanel/NotificationsManager';
import { isDev } from 'app/config/is-dev';
import getUnseenNotificationsCount from 'app/data/notifications/queries/getUnseenNotificationsCount';
import * as L from 'app/styles/layout-styled-components';
import React from 'react';
import { useMagicalQuery } from 'app/utils/blitz-utils';

import NotificationsModal from './NotificationPopupModal';
import NotificationsPopover from './NotificationsPopover';

const refetchNotifsMinutes = 10;
const refetchIntervalProd = refetchNotifsMinutes * 1000 * 60;
const refetchIntervalDev = 0;
const refetchInterval = isDev ? refetchIntervalDev : refetchIntervalProd;

export const NotificationsPanel = () => {
  const [count, { refetch }] = useMagicalQuery(
    getUnseenNotificationsCount,
    {},
    {
      refetchInterval,
    }
  );

  if (typeof window === 'undefined') return null;

  return (
    <NotificationsManager count={count} refetchSeen={refetch}>
      <L.Vertical>
        <NotificationsModal />
        <NotificationsPopover />
      </L.Vertical>
    </NotificationsManager>
  );
};
