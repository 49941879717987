import { defaultMaxWidth, maxWidthForProjectEditor } from './layout-config';
import { LayoutConfig, LayoutProps } from './types';

export const layoutConfigs: Record<LayoutConfig, LayoutProps> = {
  [LayoutConfig.HomePage]: {
    showFooter: false,
    showHeader: true,
    maxWidth: 800,
    contentPadding: 5,
    layoutProps: {
      gridTemplateRows: '1fr',
    },
    overrideNext: `
      justify-content: center;
      justify-items: center;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    `,
  },
  [LayoutConfig.Default]: {
    showHeader: true,
    showFooter: true,
    maxWidth: defaultMaxWidth,
    contentPadding: 5,
    layoutProps: {
      justifyItems: 'center',
      justifyContent: 'center',
      gridTemplateRows: '1fr',
    },
    overrideNext: `
      justify-content: center;
      justify-items: center;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    `,
  },
  [LayoutConfig.FillHeight]: {
    showHeader: true,
    showFooter: false,
    maxWidth: defaultMaxWidth,
    contentPadding: 5,
    overrideNext: `
       justify-content: center;
       justify-items: center;
       grid-template-rows: 1fr;
       grid-template-columns: 1fr;
       max-height: 100px;
    `,
    layoutProps: {
      overflow: 'hidden',
      gridTemplateRows: '1fr',
    },
  },
  [LayoutConfig.ProjectEditor]: {
    showHeader: true,
    showFooter: false,
    contentPadding: 0,
    headerMaxWidth: maxWidthForProjectEditor,
    overrideNext: `
       justify-content: center;
       justify-items: center;
       grid-template-rows: 1fr;
       grid-template-columns: 1fr;
    `,
    spacingHeaderContent: 0,
    layoutProps: {
      overflow: 'hidden',
      py: 0,
      px: 0,
    },
  },
};
