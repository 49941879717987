var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Qbp9nOTxjwrwG_xR1gE0T"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { RewriteFrames } from '@sentry/integrations';
import { ignoreError } from './sentryShared';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://a5f738badb944b72bc36233b7e6276ba@o205177.ingest.sentry.io/6034342',
  tracesSampleRate: 0.25,
  environment: `${process.env.NODE_ENV}-${process.env.RAILWAY_ENVIRONMENT}`,
  tunnel: '/api/tunnel',
  integrations: [
    new RewriteFrames({
      iteratee: (frame) => {
        frame.filename = frame.filename.replace(distDir, 'app:///_next');
        return frame;
      },
    }),
  ],
  beforeSend(event, hint) {
    const error = hint?.originalException;
    if (error && typeof error !== 'string' && ignoreError(error.name)) {
      return null;
    }

    return event;
  },
});
