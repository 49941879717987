import Head from 'next/head';
import { ErrorComponent } from '@blitzjs/next';
import { Alert, AlertIcon, AlertTitle, ChakraProvider } from '@chakra-ui/react';
import LoginForm from 'app/data/auth/components/LoginForm';
import LayoutInside from 'app/layouts/LayoutInside';

import * as L from 'app/styles/layout-styled-components';
import { customTheme } from 'app/styles/theme';
import React from 'react';

//components
import { ChildApp } from './ChildApp';

export const RootErrorFallback = ({ error, resetErrorBoundary }) => {
  if (error.name === 'AuthenticationError') {
    return (
      <ChakraProvider resetCSS theme={customTheme}>
        <Head>
          <title>Log in to continue</title>
        </Head>
        <ChildApp>
          <L.Vertical center>
            <LayoutInside>
              <Alert status="error" mb={10}>
                <AlertIcon />
                <AlertTitle>You need to be logged in to continue</AlertTitle>
              </Alert>
              <LoginForm onSuccess={resetErrorBoundary} showSignUp={false} />
            </LayoutInside>
          </L.Vertical>
        </ChildApp>
      </ChakraProvider>
    );
  } else if (error.name === 'AuthorizationError') {
    return (
      <ErrorComponent
        statusCode={error.statusCode}
        title={error?.message || 'Sorry, you are not authorized to access this'}
      />
    );
  } else {
    return (
      <ErrorComponent statusCode={error.statusCode || 400} title={error.message || error.name} />
    );
  }
};
