import Link from 'next/link';
import { MenuDivider, MenuGroup, MenuItem, Spinner, Text } from '@chakra-ui/react';
import ProjectLogo from 'app/components/ProjectLogo';
import getProjects from 'app/data/projects/queries/getProjects';
import { AwaitReturn } from 'app/types/util';
import { useMagicalQuery } from 'app/utils/blitz-utils';

import { has } from 'app/utils/utils';
import * as L from 'chakra-layout-components';

import React, { Suspense } from 'react';

type Projects = AwaitReturn<typeof getProjects>;
type Project = Projects['memberOfProjects'][0];

const ListProjects: React.FC<{ projects: Project[] }> = ({ projects }) => {
  return (
    <>
      {projects.map((project) => (
        <Link href={`/projects/${project.id}`}>
          <MenuItem key={project.slug}>
            <L.Horizontal centerV width="100%" spacing={7}>
              <ProjectLogo size={20} emoji={project.emoji} logo={project.logoImage} />
              <Text flex={1}>{project.name}</Text>
            </L.Horizontal>
          </MenuItem>
        </Link>
      ))}
    </>
  );
};

const ProjectListMenu: React.FC = () => {
  const [{ myProjects, memberOfProjects }] = useMagicalQuery(getProjects, { showAll: true });
  const allProjects = [...myProjects, ...memberOfProjects];
  if (!has(allProjects)) return null;

  return (
    <>
      <Suspense fallback={<Spinner />}>
        {has(myProjects) && (
          <MenuGroup fontSize={16} title="Your projects">
            <ListProjects projects={myProjects} />
          </MenuGroup>
        )}
        {has(memberOfProjects) && (
          <MenuGroup title="Member of projects">
            <ListProjects projects={memberOfProjects} />
          </MenuGroup>
        )}
      </Suspense>
      <MenuDivider />
    </>
  );
};

export default ProjectListMenu;
