import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import Card from 'app/components/Card';

import * as L from 'app/styles/layout-styled-components';
import updateNotification from 'app/data/notifications/mutations/updateNotification';
import { StyledDisable } from 'app/components/NotificationsPanel/NotificationList';
import { useNotifications } from 'app/components/NotificationsPanel/NotificationsManager';
import { useMagicalMutation } from 'app/utils/blitz-utils';
import { NotificationType } from 'app/utils/enums';
import { handleNotification } from 'app/components/NotificationsPanel/notification-utils/handle-notification';

//notification components
import ExternalLink from './components/ExternalLink';
import InternalLink from './components/InternalLink';
import Popup from './components/Popup';
import NoAction from './components/NoAction';
import { NotificationPopupType } from './NotificationsPopover';

const NotificationComponentMap = {
  [NotificationType.Link]: InternalLink,
  [NotificationType.ExternalLink]: ExternalLink,
  [NotificationType.Popup]: Popup,
  [NotificationType.NoAction]: NoAction,
};

type NotificationProps = {
  notification: NotificationPopupType;
  refetch: () => Promise<unknown>;
};

const Notification: React.FC<NotificationProps> = ({ notification, refetch }) => {
  const { initiator: user, type, url, id, openedAt, entity, event, shortContent } = notification;

  const { openPopup, popoverDisclosure } = useNotifications();
  const [updateNotificationMutation, { isLoading }] = useMagicalMutation(updateNotification);

  // FIXME: CHECK WHY THESE THINGS ARE NULLABLE IN THE DB
  const handled = handleNotification({ entity, notification, user: user!, event: event! });
  const meta = handled || { message: shortContent, href: url };

  const markAsSeen = async () => {
    if (!!openedAt) return null;
    await updateNotificationMutation({ id, opened: true });
    refetch?.();
  };

  const isPopup = type === NotificationType.Popup;

  const NotifComponent = NotificationComponentMap[type];

  const markAsDone = async (e) => {
    e.stopPropagation();
    await updateNotificationMutation({ id, archived: true });
    refetch?.();
  };

  return (
    <StyledDisable disabled={isLoading}>
      <Card
        onClick={() => {
          markAsSeen();
          popoverDisclosure.onClose();
          if (isPopup) {
            openPopup({
              notification,
            });
          }
        }}
        opacity={openedAt ? 0.5 : 1}
        hover={true}
        spacing={10}
        fullW
        flex={1}
        _hover={{
          '.mark-as-done': {
            opacity: 1,
            pointerEvents: 'initial',
          },
        }}
      >
        <L.Horizontal centerV spaceBetween fullW spacing={10}>
          <NotifComponent meta={meta} notification={notification} />
          <IconButton
            onClick={markAsDone}
            // opacity={0}
            pointerEvents="none"
            className="mark-as-done"
            size="xs"
            aria-label="mark as done"
            icon={<CheckIcon />}
          />
        </L.Horizontal>
      </Card>
    </StyledDisable>
  );
};

export default Notification;
