export type Meta = {
  description?: string;
  title?: string;
  imageUrl?: string;
};

export enum LayoutConfig {
  HomePage,
  Default,
  FillHeight,
  ProjectEditor,
}

export type LayoutProps = {
  showFooter?: boolean;
  showHeader?: boolean;
  maxWidth?: number | string;
  contentPadding: number;
  layoutProps: any;
  overrideNext: string;
  spacingHeaderContent?: number;
  //if maxWidth for the page is not specified use this
  headerMaxWidth?: number | string;
};
