import React, { useContext } from 'react';
import { useCreateStore } from 'app/root/use-create-store';

export const GlobalStoreContext = React.createContext<ReturnType<typeof useCreateStore>>({
  logOutGoHome: () => Promise.resolve(),
  openAuthDialog: () => {},
  // @ts-ignore
  authDisclosure: {},
  auth: [
    null,
    // @ts-ignore
    {},
  ],
});
export const GlobalStoreProvider = GlobalStoreContext.Provider;
export const useGlobalStore = () => useContext(GlobalStoreContext);
