export { useMagicalQuery } from './query';
export { useMagicalMutation } from './mutation';

export const NO_REFETCH_QUERY_OPTIONS = {
  cacheTime: Infinity,
  refetchInterval: 0,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  refetchIntervalInBackground: false,
};
