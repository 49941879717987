import { useEffect, useState } from 'react';

export const useClientQueryParams = () => {
  const [params, setParams] = useState<any>();

  useEffect(() => {
    if (typeof window !== undefined) {
      const queryParams = Object.fromEntries(new URLSearchParams(window?.location.search));
      setParams(queryParams);
    }
  }, [typeof window !== undefined]);

  return params;
};
