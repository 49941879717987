export enum EntryStatus {
  Draft = 'Draft',
  ReadyForReview = 'ReadyForReview',
  Published = 'Published',
}

export enum Role {
  Admin = 'Admin',
  User = 'User',
}

export const Reaction = {
  Sad: 'Sad',
  Meh: 'Meh',
  Happy: 'Happy',
  Excited: 'Excited',
};

export enum Tag {
  Feature = 'Feature',
  BugFix = 'BugFix',
  Improvement = 'Improvement',
}

export enum ColorMode {
  Dark = 'dark',
  Light = 'light',
}

export const TagLabels = {
  [Tag.Feature]: 'Feature',
  [Tag.BugFix]: 'Bug Fix',
  [Tag.Improvement]: 'Improvement',
};

export const StatusLabels = {
  [EntryStatus.Draft]: 'In Progress',
  [EntryStatus.ReadyForReview]: 'In Review',
  [EntryStatus.Published]: 'Live',
};

export const TerminologyLabels = {
  [EntryStatus.Draft]: 'In Progress',
  [EntryStatus.ReadyForReview]: 'In Review',
  [EntryStatus.Published]: 'Live',
};

export enum UserKeyType {
  Creator = 'creator',
  User = 'user',
  Initiator = 'initiator',
  Receiver = 'receiver',
  CreatedBy = 'createdBy',
}

export enum Webhook {
  OnRequestCreated = 'onRequestCreated',
  OnRequestApproved = 'onRequestApproved',
  OnRequestComment = 'onRequestComment',
  OnRequestMembersChatMessage = 'onRequestMembersChatMessage',
  OnDiscussionCreated = 'onDiscussionCreated',
}

export { UserProjectPermission as ProjectPermissions } from '@prisma/client';

// export enum ProjectPermissions {
//   ViewRequest = 'ViewRequest',
//   ApproveRequest = 'ApproveRequest',
//   ViewAllRequests = 'ViewAllRequests',
//   ToggleCloseRequest = 'ToggleCloseRequest',
//   ToggleLockRequest = 'ToggleLockRequest',
//   DeleteRequest = 'DeleteRequest',
//   UpdateRequest = 'UpdateRequest',
//   CreateRequestComment = 'CreateRequestComment',
//   DeleteRequestComment = 'DeleteRequestComment',
//   CanChatOnRequest = 'CanChatOnRequest',
//   CreateVariable = 'CreateVariable',
//   EditVariable = 'EditVariable',
//   DeleteVariable = 'DeleteVariable',
// }

export { NotificationEvent } from '@prisma/client';

// export enum NotificationEvent {
//   RequestApproved = 'RequestApproved',
//   RequestClosed = 'RequestClosed',
//   RequestLocked = 'RequestLocked',
//   RequestReopened = 'RequestReopened',
//   RequestUnlocked = 'RequestUnlocked',
//   RequestUpdated = 'RequestUpdated',
//   RequestComment = 'RequestComment',
//   RequestDeleted = 'RequestDeleted',
//   ChangelogPublished = 'ChangelogPublished',
// }

export { NotificationMethod } from '@prisma/client';
// export enum NotificationMethod {
//   Email = 'Email',
//   UI = 'UI',
// }

export { NotificationEntity } from '@prisma/client';
// export enum NotificationEntity {
//   Request = 'Request',
//   Changelog = 'Changelog',
// }

export enum NotificationType {
  Link = 'Link',
  ExternalLink = 'ExternalLink',
  Popup = 'Popup',
  NoAction = 'NoAction',
}
