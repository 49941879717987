import { Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import * as L from 'app/styles/layout-styled-components';
import { orderBy } from 'lodash';
import React from 'react';
import { Disable } from 'react-disable';
import Notification from './Notification';
import { NotificationPopupType } from './NotificationsPopover';

export const StyledDisable = styled(Disable)({
  width: '100%',
  flex: 1,
});

type NotificationListProps = {
  refetch: () => Promise<unknown>;
  notifications: NotificationPopupType[];
};

const NotificationList: React.FC<NotificationListProps> = ({ refetch, notifications }) => {
  const hasNotifs = notifications?.length > 0;
  const orderNotifs = orderBy(notifications, ['createdAt'], ['desc']);

  return (
    <L.Vertical py={3} spacing={13}>
      <L.Horizontal fullW centerV spaceBetween></L.Horizontal>
      {!hasNotifs && <Text>You don't have any notifications.</Text>}
      {hasNotifs && (
        <L.Vertical fullW maxHeight={800} overflowY="auto" flex={1} spacing={8}>
          {orderNotifs.map((n) => (
            <Notification refetch={refetch} notification={n} key={n.id} />
          ))}
        </L.Vertical>
      )}
    </L.Vertical>
  );
};

export default NotificationList;
