import NotificationFooter from 'app/components/NotificationsPanel/components/NotificationFooter';
import * as L from 'app/styles/layout-styled-components';
import React from 'react';
import { Text } from '@chakra-ui/react';

const Popup = ({ notification, meta }) => {
  const { message } = meta;

  return (
    <L.Vertical flex={1}>
      <Text flex={1}>{message}</Text>
      <NotificationFooter notification={notification} />
    </L.Vertical>
  );
};

export default Popup;
