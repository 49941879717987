import React from 'react';
import { useInput } from 'react-hanger';
import { Button, Heading, Input } from '@chakra-ui/react';
import { Link, LinkButton } from 'chakra-next-link';

import { useGlobalStore } from 'app/config/global-store';
import login from 'app/data/auth/mutations/login';
import { LoginSchema } from 'app/data/auth/schemas';
import * as L from 'app/styles/layout-styled-components';
import { useMagicalMutation } from 'app/utils/blitz-utils';
import { onEnter } from 'app/utils/utils';
import { FaGithub } from 'react-icons/fa';

type LoginFormProps = {
  onSuccess?: any;
  returnUrl?: string;
  showBottom?: boolean;
  showHeader?: boolean;
  showSignUp?: boolean;
  shouldRefetch?: boolean;
};

export const LoginForm: React.FC<LoginFormProps> = ({
  onSuccess,
  returnUrl,
  shouldRefetch = false,
  showHeader = true,
  showSignUp = true,
  showBottom = true,
}) => {
  const { auth } = useGlobalStore();
  const [_user, { refetch }] = auth;

  const [loginMutation, { isLoading }] = useMagicalMutation(login, {
    errorMessage: 'Invalid Credentials',
    onWin: (req) => {
      onSuccess?.(req);
      if (shouldRefetch) {
        refetch?.();
      }
    },
  });

  const email = useInput();
  const password = useInput();

  const values = {
    email: email.value,
    password: password.value,
  };

  const isValid = email.hasValue && password.hasValue && LoginSchema.safeParse(values).success;

  const submit = async () => {
    await loginMutation(values);
  };

  return (
    <L.Vertical center fullW spacing={20}>
      {showHeader && <Heading size="md">Sign in</Heading>}

      <L.Vertical center fullW maxWidth={320} spacing={15}>
        <Input autoFocus {...email.eventBind} name="email" placeholder="Email" />
        <Input
          {...onEnter(submit)}
          {...password.eventBind}
          name="password"
          placeholder="Password"
          type="password"
        />
        {showBottom && (
          <Button onClick={submit} isDisabled={!isValid} isLoading={isLoading} colorScheme="purple">
            Login
          </Button>
        )}
        <LinkButton
          href="/api/auth/github"
          isLoading={isLoading}
          leftIcon={<FaGithub />}
          nativeAnchor
        >
          Login with GitHub
        </LinkButton>
        {showSignUp && (
          <L.Horizontal spacing={10}>
            <Link href={`/signup?${returnUrl}`}>Sign up</Link>
            <Link href="/forgot-password">Forgot password</Link>
          </L.Horizontal>
        )}
      </L.Vertical>
    </L.Vertical>
  );
};

export default LoginForm;
