import { Text } from '@chakra-ui/react';

import * as L from 'app/styles/layout-styled-components';
import { Link } from 'chakra-next-link';
import Conditional from 'conditional-wrap';
import React from 'react';
import NotificationFooter from './NotificationFooter';

const ExternalLink = ({ notification, meta }) => {
  const { href, message } = meta;

  return (
    <Conditional
      condition={!!href}
      wrap={(child) => (
        <Link noUnderline isExternal flex={1} href={href}>
          {child}
        </Link>
      )}
    >
      <L.Vertical flex={1} fullW>
        <Text>{message}</Text>
        <NotificationFooter notification={notification} />
      </L.Vertical>
    </Conditional>
  );
};

export default ExternalLink;
