import React from 'react';
import Conditional from 'conditional-wrap';
import Link from 'next/link';
import { Text } from '@chakra-ui/react';

import NotificationFooter from 'app/components/NotificationsPanel/components/NotificationFooter';
import * as L from 'app/styles/layout-styled-components';

const InternalLink = ({ notification, meta }) => {
  const { href, message } = meta;

  return (
    <Conditional
      condition={!!href}
      wrap={(child) => (
        <Link href={href} passHref>
          {child}
        </Link>
      )}
    >
      <L.Vertical flex={1} fullW>
        <Text flex={1}>{message}</Text>
        <NotificationFooter notification={notification} />
      </L.Vertical>
    </Conditional>
  );
};

export default InternalLink;
